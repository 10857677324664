<template>
  <div>
    <div class="border border-solid border-blue-200">
      <datatable
        :data="loans.data"
        :columns="loans.columns"
        :query="searchQuery"
        :loading="loans.loading"
        :on-click="click"
        :selectable="true"
        :exportable="true"
        :ajax="true"
        :ajax-pagination="true"
        :url="`${this.$baseurl}/admin/personal/loans?status=disbursed`"
        :search-field="searchField"
        ref="table"
        @selection-update="selectionUpdate"
      />
    </div>

    <div
      class="fixed bottom-20 right-5 sm:right-20 ml-5 sm:mr-20 rounded-lg bg-white p-6 z-10 shadow-lg max-w-500px"
    >
      <div class="text-sm text-gray-700 mb-4">
        {{ selection.length }} Loan{{ selection.length !== 1 ? 's' : '' }}
        Selected!
      </div>

      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-4">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <div>
        <button
          class="btn btn-blue btn-sm mr-3"
          @click.prevent="confirm"
          :disabled="form.loading || !selection.length"
        >
          <ion-icon
            name="checkmark-done-outline"
            class="text-lg mr-2"
          ></ion-icon>
          <span v-if="form.loading">Moving</span>
          <span v-else>Move to Pending</span>
        </button>
        <button
          class="btn btn-gray btn-sm"
          @click.prevent="clear"
          :disabled="form.loading || !selection.length"
        >
          <ion-icon name="close-circle-outline" class="text-lg mr-2"></ion-icon>
          <span>Clear Selection</span>
        </button>
      </div>
    </div>

    <modals-user
      ref="viewModal"
      :selected-user="selectedUser"
      :allow-delete="false"
    />
  </div>
</template>

<script>
export default {
  props: {
    searchQuery: {
      type: String,
      default: ''
    },
    searchField: {
      type: [HTMLInputElement],
      default: null
    }
  },
  data() {
    return {
      selection: [],
      checkmark: require('@/assets/checkmark-base.svg'),
      exclamation: require('@/assets/exclamation.svg'),
      loans: this.$options.resource([], {
        selected: null,
        columns: [
          {
            name: 'id',
            th: 'Loan ID'
          },
          {
            name: 'name',
            th: 'Staff Name',
            render: loan => {
              if (!loan.user) {
                return 'N/A';
              }
              return `${loan.user.name} ${loan.user.last_name}`;
            }
          },
          {
            name: 'company',
            th: 'Company',
            render: loan => loan.user.company?.name
          },
          {
            name: 'merchant',
            th: 'Merchant',
            render: ({ order }) => order?.merchant?.business_name
          },
          {
            name: 'requested_amount',
            th: 'Amount Requested',
            render: loan =>
              `₦ ${this.$options.filters.currency(loan?.requested_amount)}`
          },
          {
            name: 'loan_amount',
            th: 'Disbursed Amount',
            render: loan =>
              `₦ ${this.$options.filters.currency(loan.loan_amount)}`
          },
          {
            name: 'tenure',
            th: 'Tenure',
            render: loan => `${loan?.tenure} Months`
          },
          {
            name: 'interest_rate',
            th: 'Interest Rate'
          },
          {
            name: 'account_no',
            th: 'Bank Account',
            render: loan =>
              loan.order?.merchant?.account?.account_no ??
              loan.order?.branch?.account_no ??
              loan.user.profile?.account_no
          },
          {
            name: 'bank_name',
            th: 'Bank Name',
            render: loan =>
              this.$options.filters.bankName(
                loan.order?.merchant?.account?.bank_name ??
                  loan?.order?.branch?.bank_name ??
                  loan.user?.profile?.bank_name,
                this.banks
              )
          },
          {
            name: 'source',
            th: 'Source'
          },
          {
            name: 'disbursed_at',
            th: 'Disbursement Date',
            render: (loan, date) => date
          },
          {
            name: 'offer-letter',
            th: 'Offer Letter',
            render: loan => {
              if (
                loan.status.match(/approved|accepted|disbursed/) &&
                loan?.source !== this.credpalPay
              ) {
                return `
                  <a
                    href="/${this.ENV_VUE_APP_ADMIN_PATH}/offer-letter/${loan.id}"
                    target="_blank"
                    class="btn btn-sm btn-gray-soft"
                  >
                    View Offer Letter
                  </a>
                `;
              }
            }
          }
        ]
      }),
      form: this.$options.basicForm([])
    };
  },
  computed: {
    banks() {
      return this.$store.state.banks;
    },
    selectedUser() {
      return this.loans.selected?.user;
    }
  },
  beforeMount() {
    // this.getLoans();
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    click(loan) {
      this.loans.selected = loan;
      this.$refs.viewModal.open();
    },
    clear() {
      this.$refs.table.clearSelection();
    },
    confirm() {
      this.$alert({
        title: 'Are You Sure?',
        body: `This action will clear all repayments attached to these loans.
          Please make sure the users didn't already receive payment.`,
        buttons: [
          {
            text: "I'm Sure",
            onClick: this.undisburse
          },
          { className: 'btn-orange', text: "I'm Not Sure" }
        ]
      });
    },
    selectionUpdate({ selection }) {
      this.selection = selection;
    },
    async getLoans() {
      this.$refs.table.loadAjaxData();
    },
    async undisburse() {
      this.form.loading = true;
      this.form.error = null;
      await this.sendRequest('admin.loans.personal.undisburse_many', {
        data: {
          loans: this.selection.map(item => item.row.id)
        },
        success: () => {
          this.$success({
            title: 'Loans have been moved to accepted.',
            body:
              'You can mark them as disbursed again by clicking the accepted tab.',
            button: 'Okay'
          });
          this.clear();
          this.getLoans();
        },
        error: error => {
          this.form.error = error;
        }
      });
      this.form.loading = false;
    }
  }
};
</script>
